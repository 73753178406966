<template>
  <div class="add-form">
    <div class="list-title">
      新增角色
    </div>
    <role-form :form="form" ref="form">
      <template #button>
        <el-form-item>
          <el-button type="primary" plain size="small" :loading="loading" @click="submit">
            {{ $t("handle.submit") }}
          </el-button>
        </el-form-item>
      </template>
    </role-form>
  </div>
</template>

<script>
import RoleForm from "./Form.vue";
import { roleAdd } from "api/role";

export default {
  components: {
    RoleForm
  },

  data() {
    return {
      form: {
        name: "",
        description: "",
        permissons: [],
        organizationUUID: ""
      },

      loading: false
    };
  },

  methods: {
    submit() {
      this.$refs["form"].$refs["form"].validate(valid => {
        if (valid) {
          this.loading = true;

          let data = {
            name: "",
            description: "",
            permissons: [],
            organizationUUID: ""
          };

          data.name = this.form.name;
          data.description = this.form.description;
          data.organizationUUID = this.organization;

          let permissons = [];

          for (let item of this.form.permissons) {
            for (let per of this.$refs["form"].permissionList) {
              if (item === per.name) {
                permissons.push(per);
              }
            }
          }

          data.permissons = permissons;

          roleAdd(data).then(response => {
            this.loading = false;
            if (response.code === 0) {
              this.$router.push("/admin/role");
            }
          });
        }
      });
    }
  },

  computed: {
    organization() {
      return this.$store.state.app.organization;
    }
  }
};
</script>

<style></style>
